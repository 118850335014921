import React, { Component } from "react"
import { navigate } from "@reach/router"
import { inject, observer } from "mobx-react"
import { Grid, Button, Divider } from "@material-ui/core"
import renderHTML from "react-render-html"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import withLocation from "components/withLocation.js"
import NumberFormat from "react-number-format"
import SwipeLightBox from "../../components/swipeLightBox"
import auth from "../../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import Swal from 'sweetalert2';
import "./styles/house.css"

class House extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentDidMount = async () => {
    const { id } = this.props.search
    if (id) {
      await this.props.house.fetchHouse(id)
    } else {
      navigate(`/404`)
    }
  }

  // renderlocation = houseLocation => {
  //   return houseLocation.map((item,index) => {
  //     return (<span key={index}>{item.name}</span>)
  //   })
  // }

  renderLocation = houseLocation => {
    return houseLocation.map((item, index) => (
      <span key={index}>
        {item.name}
        {index < houseLocation.length - 1 && ", "}
      </span>
    ));
  };

  renderHouseHighlight = houseIcons => {
    return houseIcons
      .filter(f => f.type === "highlight")
      .sort((a, b) => a.sequence - b.sequence)
      .map((item, index) => {
        // if (index === 4) {
        //   return true
        // }
        return (
          <Grid item key={index} xs={4} md={4} align="center">
            <div className="h-hvr-float-shadow">
              <img
                src={
                  item.icon.url ||
                  `${process.env.AWS_S3_URL}/assets/img-null.png`
                }
                style={{
                  width: "60%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  paddingBottom: "15px",
                }}
                alt="icon-highlight"
              />
            </div>

            <span className="h-house-icon-desc">{item.description || ""}</span>
          </Grid>
        )
      })
  }

  // renderHouseIcon = (houseIcons, type) => {
  //   return houseIcons
  //     .filter(f => f.type === type)
  //     .sort((a, b) => a.sequence - b.sequence)
  //     .map((item, index) => {
  //       return (
  //         <Grid item key={index} xs={6} md={3} align="center">
  //           <img
  //             src={
  //               item.icon.url ||
  //               `${process.env.AWS_S3_URL}/assets/img-null.png`
  //             }
  //             style={{ width: "80px" }}
  //             alt=""
  //           />
  //           <br />
  //           <br />
  //           <h5>{item.description || ""}</h5>
  //         </Grid>
  //       )
  //     })
  // }

  renderHouseIcon = (houseIcons, type) => {
    return houseIcons
      .filter(f => f.type === type)
      .sort((a, b) => a.sequence - b.sequence)
      .map((item, index) => {
        return (
          <div className="accommodation-icon" key={index}>
              <div className="icon-detail">
                  <img src={item.icon.url || `${process.env.AWS_S3_URL}/assets/img-null.png`}
                      style={{ height: "3em" }}
                      alt=""
                  />
              </div>
              <span>
                    {item.description || ""}
              </span>
          </div>
        )
      })
  }

  handleOpenLogin = null;

  bookingNow = (id) => {
    // console.log('redirect')
    const isAuthenticated = auth.isAuthenticated();
    if (!isAuthenticated) {
      Swal.fire({
        type: 'error',
        title: 'กรุณาเข้าสู่ระบบ',
        text: 'กรุณาเข้าสู่ระบบเพื่อทำการจอง',
        confirmButtonText: 'ตกลง',
      }).then((result) => {
        if (result.value) {
          // open login modal and after login success redirect to booking?id
          if (this.handleOpenLogin) {
            this.handleOpenLogin(`/Booking?id=${id}`); // เรียก callback เพื่อเปิด modal
          }
        }
      });
      return;
    }
    window.location.href = `/Booking?id=${id}`
  }

  render() {
    const house = this.props.house.getHouseJS()
    let houseImageHighLight = house.house_images
      ? house.house_images.find(f => f.is_highlight)
      : null
    if (!houseImageHighLight) {
      houseImageHighLight = house.house_images[0] || {
        url:
        `${process.env.AWS_S3_URL}/assets/img-null.png`,
      }
    }

    const houseImages = house.house_images
      ? house.house_images
      .sort((a, b) => a.sequence - b.sequence)
      .map(item => item.url)
      : []
    const houseIcons = house.house_icons ? house.house_icons : []
    const { id } = this.props.search
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`;
    return (
      <React.Fragment>
        <Layout page="house" onOpenLoginModal={(callback) => (this.handleOpenLogin = callback)}>
          {/* <SEO
            title={house.name || ""}
            description={house.short_detail || ""}
            image={houseImageHighLight.url}
          /> */}
          <SEO
            title="BEST HAVEN POOLVILLA"
            description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
            image={imgShare}
          />
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{ backgroundColor: 'white', padding: '30px 20px' }}>
                  <h2 style={{ backgroundColor: 'white' }}>{house.name || ""}</h2>
                  <Divider style={{ backgroundColor: '#e2c05f', height: '2px', margin: '20px 0' }} />
                  <div><FontAwesomeIcon size='lg' icon={faMapMarkerAlt} style={{ color: "#e2c05f" , marginRight: '5px' }}/> {this.renderLocation(house.locations)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* รูป Highlight */}
                <Grid item xs={12} md={12} align="center" style={{ backgroundColor: 'white', padding: '20px' }}>
                  <img
                    src={
                      houseImageHighLight
                        ? houseImageHighLight.url
                        : `${process.env.AWS_S3_URL}/assets/img-null.png`
                    }
                    alt="img_highlight"
                    className="h-hvr-grow-shadow"
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <div className="container-house">
                <div className="container-booking">
                    {/* รายละเอียดแบบสั้น */}
                    <Grid item xs={12} md={12} align="center" style={{ backgroundColor: 'white' ,padding:'20px'}}>
                      <br />
                      <span style={{fontSize:'16px'}}>{renderHTML(house.short_detail || "")}</span>

                      <Grid container spacing={1} align="center">
                        {this.renderHouseHighlight(houseIcons)}
                      </Grid>
                      <br />
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          {house.price_detail.price >
                            house.price_detail.special_price && (
                            <div>
                              <span className="h-price">จากราคาปกติ: </span>
                              <span className="h-price-normal">
                                <NumberFormat
                                  value={house.price_detail.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"THB "}
                                />
                              </span>
                              <br />
                              &nbsp;&nbsp;&nbsp;
                              <span className="h-price">ราคาพิเศษ: </span>
                              <span className="h-price-special">
                                <NumberFormat
                                  value={house.price_detail.special_price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"THB "}
                                />
                              </span>
                            </div>
                          )}
                          {house.price_detail.price <=
                            house.price_detail.special_price && (
                            <div>
                              <span className="h-price">ราคา: </span>
                              <span className="h-price-special">
                                <NumberFormat
                                  value={house.price_detail.special_price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"THB "}
                                />
                              </span>
                            </div>
                          )}
                          {house.insurance_price > 0 && (
                            <div>
                              <span className="h-price-insurance">
                                {" "}
                                ( เงินประกัน :{" "}
                              </span>
                              <span className="h-price-insurance">
                                <NumberFormat
                                  value={house.insurance_price || 0}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"THB "}
                                />
                              </span>
                              <span className="h-price-insurance">
                                {" "}
                                จ่ายวันเข้าพัก ){" "}
                              </span>
                            </div>
                          )}
                          <br />
                          <br />
                          <div>
                            <Button onClick={() => this.bookingNow(id)} variant="contained" color="secondary" fullWidth>
                              จองเลย!!
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="container-detail">
                    {/* รายละเอียดทั้งหมด */}
                    <Grid item xs={12} md={12} style={{ backgroundColor: 'white', padding: '20px'}}>
                      <h3>ภาพรวม</h3>
                      <Divider style={{ backgroundColor: '#e2c05f', height: '2px', margin: '20px 0' }} />

                      <Grid container spacing={0}>
                        <Grid item md={12}>
                          <header className="h-sub-header">คุณสมบัติที่พัก</header>
                          <div className="bg-gray">
                            <div className="main-box-icon">

                              {/* <div className="accommodation-icon">
                                  <div className="icon-detail">
                                      <img src={item.icon.url || `${process.env.AWS_S3_URL}/assets/img-null.png`}
                                          style={{ height: "3em" }}
                                          alt=""
                                      />
                                  </div>
                                  <span>
                                        {item.description || ""}
                                  </span>
                              </div> */}

                              <div className="accommodation-icon">
                                <div className="icon-detail">
                                  <img src="https://s3-ap-southeast-1.amazonaws.com/besthavenpoolvilla/house_images/20200814125208761.png"
                                        style={{ height: "3em" }}
                                        alt=""
                                  />
                                </div>
                                <span className="icon-detail-text">
                                      {house.bedrooms} ห้องนอน
                                </span>
                              </div>
                              <div className="accommodation-icon">
                                <div className="icon-detail">
                                    <img src="https://s3-ap-southeast-1.amazonaws.com/besthavenpoolvilla/house_images/20200803165446604.png"
                                        style={{ height: "3em" }}
                                        alt=""
                                    />
                                </div>
                                <span className="icon-detail-text">
                                      {house.bathrooms} ห้องน้ำ
                                </span>
                              </div>
                              <div className="accommodation-icon">
                                <div className="icon-detail">
                                    <img src="https://s3-ap-southeast-1.amazonaws.com/besthavenpoolvilla/house_images/20200803172957211.png"
                                        style={{ height: "3em" }}
                                        alt=""
                                    />
                                </div>
                                <span className="icon-detail-text">
                                      {house.beds} เตียง
                                </span>
                              </div>
                              <div className="accommodation-icon">
                                <div className="icon-detail">
                                    <img src="https://s3-ap-southeast-1.amazonaws.com/besthavenpoolvilla/house_images/20200815024253500.png"
                                        style={{ width: "40px" }}
                                        alt=""
                                    />
                                </div>
                                <span className="icon-detail-text">
                                      สูงสุด {house.max_occupants} คน
                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={0}>
                        <Grid item md={12}>
                          <header className="h-sub-header">สิ่งอำนวยความสะดวก</header>
                          <div className="bg-gray">
                            <div className="main-box-icon">
                              {this.renderHouseIcon(houseIcons, "facility")}
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={0}>
                        <Grid item md={12}>
                          <header className="h-sub-header">อุปกรณ์ห้องครัว</header>
                            <div className="bg-gray">
                              <div className="main-box-icon">{this.renderHouseIcon(houseIcons, "detail")}</div>
                            </div>
                            
                          {/* <Grid container spacing={1}>
                            {this.renderHouseIcon(houseIcons, "detail")}
                          </Grid> */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12}>
                          <header className="h-sub-header">
                            สถานที่และแหล่งท่องเที่ยวใกล้เคียง
                          </header>
                          <div className="bg-gray" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <div className="h-content">
                              {renderHTML(house.nearly_landmark || "")}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <header className="h-sub-header">กฎระเบียบบ้านพัก</header>
                          <div className="bg-gray" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <div className="h-content">
                              {renderHTML(house.term_and_condition || "")}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <header className="h-sub-header">รายละเอียดเพิ่มเติม</header>
                          <div className="bg-gray" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <div className="h-content">
                              {renderHTML(house.long_detail || "")}
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            <b>- Check In : {house.check_in || ""}</b>
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            <b>- Check Out : {house.check_out || ""}</b>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div>
                    <Button onClick={() => this.bookingNow(id)} variant="contained" color="secondary" fullWidth>
                      จองเลย!!
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <br />
              <hr />

              {/* รูปบ้านอื่น ๆ */}
              <SwipeLightBox images={houseImages} />

              <br />
              <hr />
            </Grid>
          </Grid>
        </Layout>
      </React.Fragment>
    )
  }
}

export default inject("house")(withLocation(observer(House)))
